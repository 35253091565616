<template>
	<div class="footer-bar__container" :class="invertClass">
		<hr class="footer-bar__divider" />
		<div class="footer-bar__nav-bar" :class="invertClass">
			<div class="footer-bar__nav-section footer-bar__nav-section__title">
				<button class="footer-bar__title button--link" @click="pushRoute(`home`)">
					<svg class="footer-bar__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 710.11 696.63"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon points="708.93 598.06 610.78 684.43 610.78 106.52 710.11 18.64 708.93 598.06"/><path d="M262.42,481.12c-21.24-.05-35,15.86-35.07,37.57s14.4,37.44,35.15,37.48c21.24.05,35-15.85,35.08-37.33S283.17,481.16,262.42,481.12Z"/><path d="M0,113.47V696.63H583.16V113.47ZM283.48,263.4l30,80.19,29.37-80.07,33.3.07L310.64,434.81l-31.37-.07,17.47-47.26-49-124.16ZM51.16,225l50,.1L145,348.55l44.42-123.24,48.75.1-.34,167-32.34-.07.25-124.78L159.66,392.27l-32.1-.07L82,267.33,81.71,392.1,50.81,392Zm62,357.87L50.77,415.76l37.64.07L132.33,540.7,177,416l35.72.07L149.37,583Zm149,2.48C221.9,585.3,194.93,557,195,518.87c.07-38.37,28.61-67,67.47-66.95,40.79.08,67.52,28.61,67.44,66.75C329.84,556.56,302,585.46,262.2,585.38ZM398,481.64l-19.79,0-.12,60.1c0,9.65,3.11,13.76,12.28,13.78h7.48L397.83,583a85.7,85.7,0,0,1-17.62,1.65c-24.37,0-34.49-12.38-34.44-33.86l.14-69.27-12.07,0,.06-27.28,12.07,0L346,434l32.34.07,0,20.28,19.79,0Zm132.4,48.06-92.2-.19c1.66,17.14,13.7,27.54,32,27.58,16.42,0,25.85-8.64,32.63-19.25l23.86,14.54c-7.76,16.15-25.17,33.49-56.54,33.43-39.1-.08-66.08-25.48-66-66.51.08-38.62,26.21-67,63.62-67,38.85.08,63.18,25.47,63.1,64.09C530.94,519.56,530.93,524.15,530.44,529.7Z"/><path d="M467.55,479.6c-16.41,0-26.81,10.82-28.53,25.53l58.4.12C496,490.77,485.17,479.64,467.55,479.6Z"/><path d="M109.51,0,12.2,85.86H592.46L689.51,0ZM460.88,55.59H222.36l28.7-25.33H489.58Z"/></g></g></svg>
					<div class="footer-bar__copyright">© MyVote Project 2021</div>
				</button>
			</div>
			<div class="footer-bar__nav-section">
				<div class="footer-bar__link-column-group">
					<div class="footer-bar__link-column">
						<div class="footer-bar__nav headline-xs"><a target="_blank" href="http://instagram.com/myvoteproject">
							Instagram
						</a></div>
						<div class="footer-bar__nav headline-xs"><a target="_blank" href="https://www.facebook.com/MyVoteProject2020/">
							Facebook
						</a></div>
						<div class="footer-bar__nav headline-xs"><a target="_blank" href="https://twitter.com/MyVoteProject">
							Twitter
						</a></div>
					</div>
					<div class="footer-bar__link-column">
						<div class="footer-bar__nav headline-xs" @click="pushRoute(`candidates`)">
							{{ $t("components.common.footerBar.candidates") }}
						</div>
						<div class="footer-bar__nav headline-xs" @click="pushRoute(`topics`)">
							{{ $t("components.common.footerBar.topics") }}
						</div>
						<div class="footer-bar__nav headline-xs" @click="pushRoute(`about`)">
							{{ $t("components.common.footerBar.about") }}
						</div>
					</div>
				</div>
				<div class="footer-bar__link-column">
					<div class="footer-bar__nav headline-xs" @click="openLink('https://edwardcharlesfoundation.networkforgood.com/projects/222923-myvoteproject')">
						{{ $t("components.common.footerBar.donate") }}
					</div>
					<div class="footer-bar__nav headline-xs" @click="openLink('https://docs.google.com/forms/d/e/1FAIpQLSdVf76uJ8DJf3q3Qco4BC_ED1mfoCQDMuZr-q5K55g7RD5y_g/viewform')">
						{{ $t("components.common.footerBar.volunteer") }}
					</div>
					<div class="footer-bar__nav headline-xs" @click="pushRoute(`contact`)">
						{{ $t("components.common.footerBar.contact") }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "footer-bar",
	props: {
		inverted: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		invertClass() {
			return this.inverted ? "" : "footer-bar--light";
		},
	},
	methods: {
		pushRoute(destination) {
			this.$router.push({
				name: destination,
			});
		},
		openLink(destination) {
			window.open(destination, "_blank");
		},
	},
};
</script>

<style lang="stylus" scoped>
@import '../../../styles/global.styl';
.footer-bar
	&__logo
		fill: $light-purple
		height: 5rem
		margin-top: 2rem
		margin-bottom: 1.5rem

	&__copyright
		margin-bottom: 2rem

	&__container
		background-color: $purple

		&.footer-bar--light
			background-color: $white

	&__divider
		border: none
		height: 0.15rem
		color: $light-purple
		background-color: $light-purple
		margin: 0

	&__nav-bar
		display: flex
		flex-direction: column-reverse
		// align-items: center
		background-color: $purple

		&.footer-bar--light
			background-color: $white

	&__title
		margin-right: auto
		color: $light-purple
		text-align: left

	&__nav
		color: $light-purple
		margin-top: 2rem
		margin-bottom: 2rem
		margin-right: 5rem
		cursor: pointer

	&__nav a
		color: $light-purple
		text-decoration: none

	&__link-column-group div.footer-bar__link-column
		display: inline-block

@media (min-width $pxToRem(800px))
	.footer-bar
		&__nav-bar
			flex-direction: row
			justify-content: flex-end

		&__link-column-group
			display: inline-block

		&__link-column
			display: inline-block

		&__nav-section__title
			margin-right: auto
</style>
